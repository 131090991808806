:root {
  --blue: #0E5EF1;
  --darkPurple: #1e2133;
  --paleBlue: #73BCFF;
  --darkGrey: #2D2D2D;
  --lightGrey: #F1F1F1;
  --mediumGrey: #747474;
  --lightBlue: #E7F8FF;
  --textBlue:#D4DAF8;
  --purpleBlue: #2A335F;
  --brightPurpleBlue: #303D81;
  --white: #fff;
  --black: #000;
  --purpleSlate: #3C4570;
  --fontSize-body: 16px;
  --extraDarkGrey:#212121;
  --extraDarkPurple:#15171f;

  --pinkAA: #FF3B89;
  --pink: #DD0B5F;
  --darkPink: #CF0254;
  --espressoBean:#190D05;
  --darkest: #120802;
  --peachyCream:#FCF5E5;
  --lightMaroon:#3E1C2E;
  --deepMaroon:#261125;

}


* {
  box-sizing: border-box
}

html {
  /* scroll-behavior: smooth; */
}

body,
textarea {
  margin: 0;
  font-family:'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:16px;
  line-height:28px;
}

.bodyLarge {
  font-size:18px;
  line-height: 32px;
}

.subtitle {
  font-size: 24px;
  line-height: 40px;
}

.quote {
  font-size: 40px;
  line-height: 60px;
}

a {
  color:var(--blue);
}

p {
  max-width:900px;
  
}


.color-pinkAA {
  color:var(--pinkAA) !important;
}


.section2Col p,
.industries p,
.contactUs p,
.aboutHead p {
  font-size: 20px;
  line-height: 36px;
}

h1,
.h1,
.quote {
  font-family: 'DM Serif Display', serif;
}

.h2, .h3 {
  font-family: 'Inter';
}

h1,
.h1{
  font-size: 64px;
  line-height: 72px;
  font-weight:normal;
  margin:0;
}

.our-work-frame {
  padding:80px 0;
}

h1.display,
.h1.display {
  line-height: calc(8vw);
  font-size: calc(7vw);
  color:var(--purpleSlate);
}

.card-group {
  display: flex;
  gap: 3%;
}

.h2{
  line-height: calc(5vw);
  font-size: calc(4.3vw);
  margin:32px 0;

  font-weight:bold;
}

.h3{
  font-size: 34px;
  line-height: 48px;
  font-weight:bold;
  margin:0;
}

.h4{
  font-size: 24px;
  line-height: 40px;
  margin:0;
}

ul {
  padding:0;
  margin:0;
  list-style: none;
}

input,
textarea {
  padding:10px 15px;
  border-radius:6px;
  border:1px solid #AFAFAF;
  color:var(--darkGrey);
  font-size:var(--fontSize-body);
}

.eyebrow {
  font-family: 'Inter';
  font-size:12px;
  text-transform: uppercase;
  color:var(--mediumGrey);
  display: block;
  padding-bottom:24px;
  margin-top:16px;
  margin-bottom:16px;
  border-bottom:1px solid rgba(45,45,45,0.1);
  letter-spacing: 3px;
  font-weight:bold;
  &.large{
    font-size: calc(2vw);
    line-height:calc(3vw);
    letter-spacing:6px;
    border-bottom:0;
    padding:0;
  }
}

.li-theme {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* @media (prefers-color-scheme: dark) {
 body,
 input,
 textarea,
 button{
  color:#fff;
 }
} */
