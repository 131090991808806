body {
  background:var(--espressoBean);
}

.App {
  min-height:100vh;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.top-banner {
  background:var(--pink);
  width: 100%;
  color:var(--peachyCream);
  text-decoration: none;
  font-size:16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height:3px;
}

a.top-banner:hover {
  background:var(--darkPink);
  padding:8px 30px;
}

.App-logo {
  height:37px;
}

.navbar {
  padding:21px 0;
  /* position: sticky; */
  // top: 44px; // this is for the top ribbon
  
  z-index: 500;
  /* container-type:inline-size; */
  position:absolute;
}

.logo-link {
  display: flex;
  padding: 2px 3px;
}

section, 
.section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Content-wrap {
  width:100%;
  max-width:1500px;
  margin:0 30px;
}

main {
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  > div {
    width:100%;
  }
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: unset;
  padding:unset;
  .nav-mobile-tools {
    display: flex;
    .nav-mobile-menu-button,
    .nav-mobile-close-button {
      display: none;
    }
    button {
      background:transparent;
      padding:0;
    }
  }
  
}

.main-nav {
  display: flex;
  gap:32px;
}

.main-nav > li {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-nav li a {
  text-decoration: none;
  color:var(--darkGrey);
  font-weight: 600;
  line-height:24px;
  border-bottom: 3px solid transparent;
  font-size:18px;
  padding:16px 0;
  opacity:0.7;
  &.active{
    border-bottom: 3px solid var(--pink);
    opacity:1;
  }
  &:hover {
    opacity:1;
  }
}

.main-nav li .our-work-btn {
  text-decoration: none;
  font-weight: 600;
  line-height:24px;
  border-bottom: 3px solid transparent;
  font-size:18px;
  padding:16px 0;
  opacity:0.7;
  border-radius:0;
  font-family:'Inter';
  background:transparent;
  color:var(--peachyCream);
  &:hover {
    opacity:1;
  }
  &.active{
    opacity:1;
    border-bottom: 3px solid var(--pink);
  }
}


.main-nav li.li-theme {
  margin-left:0;
  border-bottom:0;
  position: relative;
}

.mag-nav-dd-item {
  position:relative;
  .popover-menu {
    left:-24px;
    right:auto;
  }
}





.main-nav li a.btn-primary {
  color:#fff;
}

.card-group {
  overflow:auto;
  .card-group-inner {
    display: flex;
    flex-direction:row;
    gap: 42px;
    .btn-secondary {
      margin-top:16px;
    }
  }
}


/* POPOVER BUTTON */

.popover-menu {
  display: none;
  position:absolute;
  top:50px;
  right:0;
  padding: 8px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;
  background: var(--deepMaroon);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);

  &.true {
    display: inline-flex;
  }
}



.popover-menu li {
  display: flex;
  min-width: 230px;
  flex-direction: column;
  width:100%;
  
  a {
    opacity:1;
  }
}


.popover-menu .pop-menu-button {
  padding:12px 20px;
  display: block;
  background: var(--lightMaroon);
  border-radius: 4px;
  border-bottom:0;
  &:hover {
    background:#4F243B;
  }
  &.active {
    background:transparent;
    border-bottom:0;
    
  }
}
.popover-menu .pop-menu-button.active:hover {
  background:transparent;
}

/* .main-nav li:hover {
  border-bottom:3px solid var(--blue);
} */


.hero {
  position:relative;
  display: block;
  background:url('./images/backdrop.jpg');
  background-size:cover;
}

.sectionQuote {
  background:url('./images/backdrop-small.jpg');
  background-size:cover;
}

/* .whiteSquare {
  background:#fff;
  position:absolute;
  top:0;
  bottom:315px;
  right:0;
  left:50%;
} */

.hero-content { 
  padding-top:156px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  z-index: 1;
  h1 {
    margin-bottom:40px;
  }
}







.hero .Content-wrap {
  position: relative;
  /* padding-bottom: 140px; */
  display:flex;
  gap:64px;
  margin-bottom:104px;
}

.hero .Content-wrap.logos {
  margin-bottom:28px;
  display:flex;
  flex-direction: row;
  gap:0;
}

.logo-ribbon {
  display: flex;
  width:100%;
  li {
    width:100%;
    img {
      width:100%;
    }
  }
}

.hero .content-block {
  margin-left: 30px;
  position: relative;
  z-index: 200;
  align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
  .btn-primary {
    margin-bottom:50px;
  }
}

.hero .content-block {
  flex: 3;
}

.hero .content-block-alt {
  max-width: 50%;
  flex: 2;
}

.hero .content-block-alt img {
  max-width:100%;
}

.hero .content-block h1 {
  margin-top:56px;
  margin-bottom:40px;
}

.hero .content-block p {
  margin-bottom:56px;
}

.hero .btn-primary {
  width:auto;
}

.pronoun {
  color:var(--blue);
}

button,
a.btn-primary,
a.btn-secondary {
  font-weight:600;
  padding: 10px 24px;
  font-size: 16px;
  border:0;
  border-radius:30px;
  line-height:24px;
  display: inline-block;
  cursor:pointer;
  transition: background 0.2s;
  &.large {
    padding:16px 45px;
    font-size:18px;
    line-height:32px;
  }
}

button.theme-btn {
  padding: 0;
  font-size:14px;
  color: var(--purpleSlate);
  padding: 4px 12px 4px 4px;
  display: flex;
  align-items: center;
  background-color: transparent;
  &::before {
    display: inline-block;
    content:'';
    height:32px;
    width:32px;
    margin-right:3px;
    background:url('./images/icon_theme.svg') no-repeat left center;
  }
  &:hover {
    background: rgba(0,0,0,.05);
  }
}

.popover-menu button {
  padding: 0;
  white-space: nowrap;
  display: flex;
  text-align: left;
  width: 100%;
  padding: 10px 20px;
  padding-left:50px;
  color: var(--purpleSlate);
  font-size:14px;
  justify-content: space-between;
  border-radius: 4px;
}

.popover-menu .active button {
  flex-direction: row-reverse;
}

button.btn-theme-default {
  background:url('./images/icon-refresh.svg') no-repeat 14px center;
}

button.btn-theme-light {
  background:url('./images/icon-sun.svg') no-repeat 14px center;
}

button.btn-theme-dark {
  background:url('./images/icon-moon.svg') no-repeat 14px center;
}

.btn-primary,
a.btn-primary {
  color:#fff;
  background:var(--pink);
  text-decoration: none;
}

.btn-secondary,
a.btn-secondary {
  color:#fff;
  text-decoration: none;
}

.btn-primary:hover,
.btn-primary:focus-visible {
  background:#4F243B;
}

.btn-secondary:hover,
.btn-secondary:focus-visible {
  background:#000;
}

.btn-secondary.outline {
  background:transparent;
  border:2px solid var(--darkGrey);
  color:var(--darkGrey);
  &:focus-visible {
    padding: 12px 38px;
  }
}

.btn-secondary.outline:hover {
  background: var(--darkGrey);
  color:#fff;
}

:focus-visible {
  border: 0 !important;
  outline: 0;
  position:relative;

  &::after{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 3px;
    padding: 2px; /* control the border thickness */
    background: linear-gradient(90deg, #0d90ff 0%, #FFCF00 49%, #fc0fff 80%, #1796ff 100%);
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    pointer-events: none;
  }
  
}

.btn-primary,
.btn-secondary {
  &:focus-visible::after {
    border-radius: 50px;
  }
}

.popover-menu button:focus-visible::after {
  border-radius: 3px;
}

.linkedIn-btn {
  display: inline-block;
  line-height: 0;
  &::after {
    border-radius:0;
  }
}

.aboutContent {
  .linkedIn-btn img {
    width:32px;
  }
}

.section-head {
  margin-top:101px;
  .Content-wrap{
    display:flex;
    padding: calc(7vw) 0;
  }
  .content-block{
    flex:3;
  }
  .content-block-alt{
    flex:2;
  }
}

.section-body {
  .Content-wrap{
    padding-bottom: 120px;
  }
}

.cs-nav {
  display:flex;
  justify-content: space-between;
  margin-top: 74px;
  .navlink {
    display:flex;
    flex-direction:column;
    color:var(--pinkAA);
    font-size:24px;
    line-height:40px;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
    &::after {
      height:24px;
      min-width:180px;
      display:block;
      content:'';
    }
  }
  .cs-nav-back {
    align-items:flex-end;
    &::after {
      background:url(./images/wide-arrow-left-large.png) no-repeat top right;
    }
  }
  .cs-nav-forward {
    align-items:flex-start;
    &::after {
      background:url(./images/wide-arrow-right-large.png) no-repeat top left;
    }
  }
}

.card-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  .card-column {
    display: flex;
    flex-direction:column;
    gap:24px;
  }
}

.smallCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius:35px;
  border-radius: 35px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: var(--deepMaroon);
  padding:32px;
  .h4 {
    font-weight: bold;
    margin-top: 32px;
  }
}

.page-about {
  .section-head {
    .Content-wrap {
      .content-block {
        flex:2;
      }
    }
    
  }
}

.page-values .item-wrapper {
  display:flex;
  flex-direction:column;
  gap:64px;
}

.page-values {
  .section-body {
    background:url('./images/backdrop-square.jpg') no-repeat center;
    background-size:cover;
    padding-top:32px;
  }
}

.largeCard {
  display: flex;
  align-items: center;
  gap:48px;
  .content p {
    margin-bottom: 32px;
  }
  img {
    border-radius:20px;
    width: 40%;
  }
  &.reverse {
    flex-direction:row-reverse;
  }
}

.page-about .section-head .content-block-alt {
  display:flex;
  align-items:flex-end;
}

.page-about {
  .headline {
    font-size: 20px;
    line-height: 32px;
  }
  .section-core {
    padding-top:112px;
    padding-bottom:0;
    background:url('./images/backdrop-alt1.jpg') no-repeat center;
    background-size:cover;
  }
  .section-partners {
    background:url('./images/backdrop-alt2.jpg') no-repeat center;
    background-size:cover;
  }
}

.sectionQuote {
  padding: 112px 0;
  .quote-main {
    font-size: 48px;
    line-height: 62px;
    max-width: unset;
    font-family: 'DM Serif Display';
  }
  .quote-sub {
    max-width: unset;
    font-size: 32px;
    line-height: 48px;
  }
}

.expertise {
  padding:132px 0;
  background:var(--lightGrey);    
  z-index: 1;
}

.expertise .sectionHead {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.expertise .cardHead {
  border-bottom: 2px solid #D2D2D2;
}

.visually-hidden { 
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(50%); /*modern browsers, clip-path works inwards from each corner*/
  white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}

.cardHead {
  padding-bottom:15px;
  display: flex;
  align-items: center;
}

.cardHead .h4 {
  margin-left:16px;
  font-weight: normal;
}

.row {
  display:flex;
  
}

.card {
  margin-right:3%;
}

.card:last-child {
  margin-right:0;
}

.andMore {
  background:#fff;
  padding:124px 0;
  text-align:center;
  display:flex;
  position: relative;

  z-index: 1;
}

.andMore .Content-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0;
  padding:0 30px;
}

.plus-icon {
  position: absolute;
  top: -30px;
}

.industries {
  background:var(--purpleBlue);
  padding:132px 0;
  color:#fff;
}



.industries .Content-wrap {
  display:flex;
}

.industriesHead {
  flex: 1;
  margin-right: 12%;
  margin-left: 08.333333333%;
}

.industriesContent {
  flex: 1;
  display: grid;
  grid-template-columns: auto auto;
  flex-wrap: wrap;
}

.industriesContent .cardHead {
  padding-bottom: 15px;
    display: flex;
    align-items: center;
    flex: 1 1;
    margin-right: 44px;
}

.industriesContent .cardHead .h4 {
  white-space: nowrap;
}

.section2Col {
  z-index:1;
  position: relative;
}



.section2Col-wrap {
  display: flex;
  padding-top: 42px;
  align-items: center;
  flex-direction:column;
  align-items: flex-start;
  gap: 32px;
}

.section2Col.reverse .section2Col-wrap {
  flex-direction:row-reverse;
}





.section2Col.reverse .col1 {
  margin-left:0;
}

.section2Col.reverse .col2 {
  margin-left:30px;
}

.section2Col-wrap .col1 img {
  width:100%;
  border-radius: 20px;
}


.section2Col-wrap .col2 div:first-child {
  display:flex;
  flex-direction:column;
  padding-left:20%;
  padding-top: 20px;
}

/* Clients Section  */

.sectionClients {
  background:var(--lightGrey);
  padding:100px;
}

.sectionClients .Content-wrap {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  row-gap:32px
}

.sectionClients .Content-wrap div {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* About US */

.aboutUs .Content-wrap {
  padding-top:120px;
  padding-bottom:100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width:920px;
}

.aboutHead {
  display:flex;
  flex-direction:column;
  padding-bottom:100px;
}

.aboutUs {
  background:#fff;
}

.aboutUs .row,
.partners .row {
  gap:60px;
}

.aboutBody {
  display:flex;
  justify-content: center;
}

.avatar-img {
  max-width: 375px;
  width:100%;
  margin-bottom:32px;
  border-radius:100%;
}

.aboutBody h4 {
  color:var(--blue);
}

.aboutContent {
  padding-left:50px;
  padding-right:50px;
  max-width:700px;
  .h4 {
    font-size:32px;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
}

.partnersHead .h3 {
  text-align: center;
  margin-bottom: 72px;
  margin-top:112px;
  font-size:48px;
}

.aboutContent .title,
.partnersContent .title {
  font-weight: bold;
  display: block;
  font-size:16px;
  margin-bottom:16px;
  margin-top:16px;
}

.aboutContent .pronoun,
.partnersContent .pronoun {
  margin-top:-6px;
  display: block;
  margin-bottom:18px;
}

.aboutContent p,
.partnersContent p {
  margin-top:0;
}

/* Partners */




.partnersCard {
  flex:1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.case-study {
  .hero-image {
    width:100%;
    border-radius:32px;
  }
  .details-block {
    padding-top:112px;
    padding-bottom:112px;
    display: flex;
    
    h3 {
      text-transform: uppercase;
      font-size:20px;
      letter-spacing: 5px;
    }
    p {
      font-size:24px;
      line-height:40px;
    }
    .col1 {
      flex: 2;
      margin-right:5%;
    }
    .col2 {
      flex: 1;
      h3 {
        padding-bottom: 24px;
        border-bottom: 1px solid var(--peachyCream);
      }
      li {
        font-family: 'DM Serif Display', serif;
        font-size:32px;
        line-height:64px;
      }
    }
  }
  .images-block {
    display:flex;
    flex-direction:column;
    gap:48px;
    .row {
      display:flex;
      gap:24px;
      img {
        border-radius:24px;
        width:100%;
      }
    }
  }
}



.contactUs-wrap {
  display:flex;
  padding-top:120px;
  padding-bottom:120px;
}

.contactUs-wrap .col {
  flex:1;
}


// .contactUs-wrap .col2 {
//   max-height: 450px;
//   overflow: hidden;
// }

.contactUs-wrap .col2 > div:first-child {
  display:flex;
  flex-direction:column;
  padding-left:10%;
  padding-top: 20px;
}

.contactUs-wrap .col2 input {
  margin-bottom:16px;
}

.contactUs-wrap .col2 textarea {
  height:150px;
  margin-bottom:32px;
}

footer {
  padding:70px 0 24px;
  background:var(--darkest);
  color:#fff;
  display:flex;
  justify-content: center;
  width:100%;
}

footer .Content-wrap {
  display:flex;
  justify-content: space-between;
  flex-direction: column;
  .foot-main {
    display: flex;
    align-items: center;
    margin-bottom:72px;
    p{
      margin-left:30px;
      color:var(--peachyCream);
      a{
        color:var(--peachyCream);
        font-weight: bold;
        &:hover {
          background:rgba(255,255,255,0.1);
        }
      }
    }
  }
  .foot-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  p.copyright {
    color:var(--peachyCream);
    margin-bottom:0;
  }
}

footer .mark {
  height:86px;
}

////////////////////////////////
/// 
/// Acessibility Statement
/// 
///////////////////////////////

.page-a11y {
  p {
    font-size:24px;
    line-height:40px;
    color:var(--peachyCream);
  }
  a {
    font-weight: bold;
    border-radius:3px;
    &:hover {
      background:rgba(0,0,0,0.1);
    }
  }
}

////////////////////////////////
/// 
/// DARK THEME 
/// 
///////////////////////////////

/* @media (prefers-color-scheme: dark) { */
.App.darkTheme {

  background:var(--espressoBean);
  color:var(--peachyCream);

  body {
    background:var(--espressoBean);
  }

  .display {
    color:var(--peachyCream);
  }


  h1.display .highlight {
    color: var(--pink);
  }

  .page-a11y {
    p {
      color:var(--peachyCream);
    }
    a {
      color:var(--pinkAA);
      &:hover {
        background:rgba(255,255,255,0.1);
      }
    }
  }

  button.theme-btn {
    color:var(--lightGrey);
    &::before {
      background: url(./images/icon_theme_dark.svg) no-repeat left center;
    }
    &:hover {
      background: rgba(255,255,255,.1);
    }
  }

  .popover-menu {
    background:var(--deepMaroon);
    li {
      
      .active {
        background:transparent;
      }
    }
    button {
      color: var(--lightGrey);
    }
  }

  button.btn-theme-default {
    background:url('./images/icon-refresh-dark.svg') no-repeat 14px center;
  }

  button.btn-theme-light {
    background:url('./images/icon-sun-dark.svg') no-repeat 14px center;
  }

  button.btn-theme-dark {
    background:url('./images/icon-moon-dark.svg') no-repeat 14px center;
  }

 
  
  // .section2Col,
  // .partners {
  //   background:var(--extraDarkPurple);
  //   color:var(--textBlue);
  // }

  // .hero-content,
  // .whiteSquare,
  // .navbar,
  // .andMore,
  // .sectionClients,
  // .section2Col.reverse,
  // .ourValues{
  //   background:var(--darkPurple);
  //   color:var(--textBlue);
  // }

  .eyebrow {
    color:var(--peachyCream);
    border-bottom: 1px solid rgb(255 255 255 / 25%);
    &.large {
      border-bottom:0;
    }
  }

  


  .expertise .cardHead {
    border-bottom: 2px solid #4d4d4d;
  }


  .expertise,
  .aboutUs {
    background:var(--extraDarkPurple);
    color:var(--textBlue);
  }

  .industries {
    background:var(--brightPurpleBlue);
    color:var(--lightGrey);
  }

  .main-nav li a {
    color:var(--peachyCream);
  }


  footer {
    background:var(--darkest);
    color:var(--peachyCream);
  }

  .btn-secondary, a.btn-secondary {
    border:2px solid var(--lightGrey);
    color:var(--lightGrey);
  }

  .btn-secondary:hover, a.btn-secondary:hover {
    background: var(--extraDarkGrey);
  }

  .btn-secondary.outline {
    background:transparent;
    border:2px solid var(--lightGrey);
    color:var(--lightGrey);
  }
  
  .btn-secondary.outline:hover {
    background: var(--purpleSlate);
    color:#fff;
  }



}

////////////////////////////////
/// 
/// RESPONSIVE
/// 
///////////////////////////////


@media (min-width:1500px) {
  h1.display {
    font-size: 112px;
    line-height: 128px;
  }

.h2 {
  line-height: 72px;
  font-size: 64px;
}

.eyebrow.large {
  font-size: 32px;
}

  .hero .content-block {
    max-width:unset;
  }

  .hero .Content-wrap {
    margin-top:32px;
  }

  .hero .content-block h1 {
    margin-top:50px;
    margin-bottom: 50px;
  }

  .section-head {
    .Content-wrap {
      padding: 120px 0;
    }
  }
}

// >> min 

// 900 <--> 1500
// is the normal size range in bulk of styles
// 1500+ has styles for widescreen
// 900 and below for tablet
// 600 and below for phone
// maybe a 400 and below for smaller phones

// max <<

@media (max-width: 1200px) {
  .main-nav {
    gap:16px;
    li a,
    li .our-work-btn{
      font-size:16px;
    }
  }
  .nav-tools {
    a.btn-primary {
      font-size:14px;
    }
  }

  .smallCard {
    padding:24px;
  }

  .largeCard {
    .h3 {
      font-size:24px;
    }
  }

  .page-about {
    .section-core {
      padding:32px 0;
      .Content-wrap {
        padding-bottom:0;
      }
    }
    .section-head {
      .Content-wrap {
        flex-direction: column;
        .content-block {
          flex:2;
        }
      }
      
    }
  }
 


.section-our-work {
  .Content-wrap {
   margin:0;
   h2 {
     padding-left:30px;
     &.eyebrow {
      padding-left:0;
     }
   }
   .section2Col-wrap .col1 img {
     height:auto;
     width:380px;
   }
   .card-group {
    padding-left: 30px;
     padding-right: 30px;
     padding-bottom: 72px;
     .section2Col-wrap.es {
      margin-right: 30px;
      margin-left:30px;
     }
   }
  }
 }


}




@media (max-width: 900px) {

    nav {
    
      background:transparent;
      
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 34px;
      
      .nav-mobile-tools {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .nav-mobile-close-button {
          display: none;
        }
        .nav-mobile-menu-button {
          display: block;
        }
        .logo-link {
          width:150px;
        }
        button {
          width:32px;
        }
      }
      
  
      .main-nav {
        flex-direction: column;
        width:100%;
        display: none;
        .popover-menu {
          position: static;
          background: transparent;
          .pop-menu-button {
            background:transparent;
          }
        }
        li .our-work-btn {
          text-align: left;
        }
      }
      .nav-tools {
        width: 100%;
        display: none;
      }
      &.mobile-nav.true {
        background: var(--espressoBean);
        position: fixed;
        padding: 24px 20px;
        .nav-mobile-menu-button {
          display: none;
        }
        .nav-mobile-close-button {
          display: block;
        }
        .main-nav{
          display: flex;
        }
        .nav-tools {
          display: flex;
        }
      }
    }

    

  .page-a11y h1 {
    margin-top:80px;
  }

  .page-a11y p {
    font-size: 20px;
    line-height: 34px;
  }

  h1 {
    font-size: 48px;
    line-height: 64px;
    &.display {
      line-height: calc(11vw);
      font-size: calc(9vw);
    }
  }

  .eyebrow.large {
    font-size: calc(3vw);
  }

  .h2 {
    line-height: 8vw;
    font-size: 6.5vw;
  }

  .hero .content-block {
    margin-bottom:0;
    .btn-primary {
      margin-bottom:0;
    }
  }

  .card-wrapper {
    flex-direction: column;
    .card-column {
      flex-direction: row;
    }

  }

  .hero-content {
    padding-top: 96px;
  }

  .hero .content-block h1 {
    margin-bottom: 24px;
  }

  .hero .content-block-alt {
    max-width:unset;
  }


  .hero .Content-wrap {
    flex-direction: column;
    gap:0;
    margin-bottom: 32px;
  }

  .sectionQuote {
    padding: 48px 0;
  }

  .largeCard {
    flex-direction:column;
    max-width: 650px;
    &.reverse {
      flex-direction:column;
    }
    img {
      width:100%;
      max-width: 500px;
      align-self: flex-start;
    }
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  // sub pages header section is .section-head
  // sub pages body section is .section-body

  .section-head {

    .Content-wrap {
      padding: 16px 20px 72px;
    }
    .content-block {
      flex: 5 1;
    }
    .content-block-alt {
      flex:1;
    }
  }

  .page-values {
    .section-body {
      padding-top:0;
    }
  }
  

  .partnersBody {
    display: flex;
    flex-direction: column;
  }

  .partnersCard {
    margin-left:0;
    margin-bottom:32px;
    max-width:unset;
  }

  .partnersContent {
    margin-left:0;
  }

  .partnersBody .aboutBody {
    align-items: unset;
}

   /* -- contact section --*/

  .contactUs-wrap {
    flex-direction: column;
  }

  .contactUs-wrap .col1 {
    margin-bottom:48px;
    margin-left: 0;
    max-width: 600px;
  }

  .contactUs-wrap .col2 {
    max-width:600px;
  }

  .contactUs-wrap .col2 div:first-child {
    padding-left:0;
  }

  .case-study {
    .details-block {
      flex-direction: column-reverse;
      gap: 64px;
      padding-top: 64px;
    }
  }

}


@media (max-width: 600px) {


  .page-a11y h1 {
    margin-top:40px;
  }

  .top-banner {
    font-size: 14px;
    span {
      display: none;
    }
  }
  
  .aboutContent {
    padding-left: 0;
    padding-right: 0;
    max-width: 700px;
}

  footer .Content-wrap {
    .foot-main {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      gap:50px;
      p {
        margin-left:0;
        text-align: left;
      }
      a {
        margin:0;
      }
    }
    p.copyright {
      margin-top: 30px;
    }
  }

  .partnersBody .aboutBody {
    align-items: unset;
  }

  .Content-wrap {
    padding:0 20px;
    margin:0;
  }


  h1 {
    font-size: 36px;
    line-height: 48px;
  }


  .hero .content-block h1 {
    margin-top:8px;
  }

  h1.display {
    line-height: calc(18vw);
    font-size: calc(16vw);
  }

  footer .Content-wrap {
    flex-direction: column;
    text-align: center;
    gap: 50px;
  }

  a.btn-primary.large {
    padding: 8px 45px;
  }

  button,
  .btn-primary,
  .btn-secondary {
    width: 100%;
    text-align: center;
  }


  .hero .hero-content {
    padding-top:104px;
    .content-block {
      margin-left:0;
      .btn-primary {
        width:100%;
        margin-top:32px;
      }
    }
  }

  .hero .Content-wrap.logos {
    flex-direction: column;
  }

  .section-head {
    .Content-wrap {
      padding: 0 20px 72px;
    }
  }

  .partnersHead .h3 {
    margin-top: 32px;
    font-size: 32px;
}

  .eyebrow.large {
    font-size: 4vw;
  }

  .card-wrapper {
    .card-column {
      flex-direction: column;
    }

  }

  .smallCard {
    padding:20px;
    
      .h4 {
        margin-top:16px;
      }
      p{
        margin:4px 0;
      }
    
  }

  .case-study .details-block p {
    font-size: 18px;
    line-height: 32px;
}

  .section-our-work {
    .Content-wrap {
      padding:0;
      .our-work-frame {
        padding: 40px 0;
      }
      .h2 {
        line-height: 10vw;
        font-size: 9vw;
        padding-left:20px;
      }
      .card-group {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 0;
        .card-group-inner {
          gap: 2%;
      }
    }
      .section2Col-wrap {
        width: 88vw;
        gap: 8px;
        padding-top: 16px;
        .col1 img {
          width:100%;
        }
        .col2 {
          padding: 0 16px;
        }
      }
    }
  }




.cs-nav {
  .navlink {
    font-size:16px;
    line-height:24px;
    &::after {
      min-width:86px;
    }
  }
  .cs-nav-back {
    &::after {
      background:url(./images/wide-arrow-left-small.png) no-repeat top right;
    }
  }
  .cs-nav-forward {
    &::after {
      background:url(./images/wide-arrow-right-small.png) no-repeat top left;
    }
  }
}

  .sectionQuote {
    padding: 32px 20px;
    .quote-main {
      line-height: 11.5vw;
      font-size: 9vw;
    }
    .quote-sub {
      line-height: 9vw;
      font-size: 6vw;
    }
}

  .contactUs-wrap {
    padding-top:60px;
  }


}


@container (min-width: 630px) {
  .industriesContent {
    grid-template-columns: auto;
  }
}
